.sidebar {
    width: 280px;
    height: 100vh;
    background: #ffffff;  /* Pure white background */
    color: #2c3e50;
    padding: 20px 0;
    position: fixed;
    left: 0;
    top: 0;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 1000;
    font-size: 14px;
    border-right: 1px solid #f0f0f0;
}


/* Adjust main content when sidebar is collapsed */
.sidebar.collapsed + .main-content {
    margin-left: 64px;
}

.sidebar.collapsed {
    width: 64px;
}

.sidebar h2 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}

.sidebar-section {
    margin: 4px 0;
}

.main-link {
    font-size: 13px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
}

.sub-links {
    height: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    opacity: 0;
}

.sub-links.open {
    height: auto;
    opacity: 1;
    margin-top: 4px;
    padding-left: 12px;
    border-left: 2px solid #e6f6e6;
}

.sub-link {
    padding: 10px 16px !important;
    font-size: 12px;
    opacity: 0.9;
    margin: 2px 0;
    background: #ffffff;
}

.sub-link:hover {
    background: #f0faf0;
}

.sidebar-label {
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #2d3748;
}

.sidebar.collapsed .sidebar-label,
.sidebar.collapsed .sub-links,
.sidebar.collapsed h2 {
    display: none;
}

.sidebar.collapsed .sidebar-link {
    padding: 10px;
    justify-content: center;
}

.sidebar.collapsed .sidebar-icon {
    margin-right: 0;
}

.sidebar.collapsed .main-link {
    padding: 10px;
}

.sidebar-header {
    padding: 0 16px 16px;
    border-bottom: 1px solid #f0f0f0;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
}

.toggle-btn {
    background: #f0faf0;
    border: none;
    color: #00a67e;
    cursor: pointer;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    border-radius: 8px;
    width: 32px;
    height: 32px;
}

.toggle-btn:hover {
    background: #e6f6e6;
}

.toggle-btn svg {
    width: 16px;
    height: 16px;
    transition: transform 0.3s ease;
}

.sidebar.collapsed .toggle-btn svg {
    transform: rotate(180deg);
}

.sidebar-link {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    color: #4a5568;
    text-decoration: none;
    transition: all 0.3s ease;
    position: relative;
    margin: 4px 8px;
    border-radius: 8px;
}

.sidebar-link:hover {
    background: #f0faf0;  /* Very light green background on hover */
    color: #00a67e;  /* Slightly darker green for text */
}

.sidebar-link.active {
    background: #e6f6e6;  /* Light green background for active state */
    color: #00a67e;
}

.sidebar-link.active::before {
    display: none;  /* Removing the left border indicator */
}

.sidebar-icon {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    color: #00a67e;  /* Green color for icons */
}

.sidebar-icon svg {
    width: 16px;
    height: 16px;
    transition: all 0.3s ease;
}

.sidebar-link:hover .sidebar-icon svg {
    transform: scale(1.1);
    color: #00a67e;
}

.sidebar-link.active .sidebar-icon {
    color: #00a67e;
}

/* Scrollbar styling */
.sidebar::-webkit-scrollbar {
    width: 4px;
}

.sidebar::-webkit-scrollbar-track {
    background: #ffffff;
}

.sidebar::-webkit-scrollbar-thumb {
    background: #e6f6e6;
    border-radius: 4px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
    background: #d1e7d1;
}

.arrow-icon {
    margin-left: auto;
    color: #a0aec0;
}

.arrow-icon.open {
    transform: rotate(-180deg);
    color: #00a67e;
}

/* Active state */
.sidebar-link.active {
    background: #f0faf0;
    color: #00a67e;
    font-weight: 500;
} 