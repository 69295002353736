/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap');

/* Reset and base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0f172a;
  line-height: 1.6;
  background-color: #f8fafc;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 1rem;
}

/* Header styles */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  z-index: 1100;
}

/* Footer styles */
footer {
  position: relative;
  z-index: 900;
  width: 100%;
}

/* Typography styles */
p {
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.7;
}

a {
  color: #4a90e2;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #357abd;
}

/* Form elements global styles */
input, select, textarea, button {
  font-family: 'Inter', sans-serif;
}

/* Headings specific styles */
h1 { font-size: 2.5rem; }
h2 { font-size: 2rem; }
h3 { font-size: 1.75rem; }
h4 { font-size: 1.5rem; }
h5 { font-size: 1.25rem; }
h6 { font-size: 1rem; }

/* Responsive typography */
@media (max-width: 768px) {
  h1 { font-size: 2rem; }
  h2 { font-size: 1.75rem; }
  h3 { font-size: 1.5rem; }
  h4 { font-size: 1.25rem; }
  body { font-size: 0.95rem; }
}

/* Add spacing for content below header */
main, .main-content {
  padding-top: 70px; /* reduced from 90px to match header height exactly */
  min-height: calc(100vh - 70px); /* added to ensure content fills remaining space */
}