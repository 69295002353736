.wallet-container {
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.wallet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  padding: 24px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.wallet-balance {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.balance-label {
  font-size: 14px;
  color: #666;
  margin-bottom: 4px;
}

.balance-amount {
  font-size: 32px;
  font-weight: bold;
  color: #2c3e50;
}

.wallet-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 24px;
  margin-bottom: 32px;
}

.stat-card {
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-align: center;
}

.stat-card h3 {
  font-size: 16px;
  color: #666;
  margin-bottom: 12px;
}

.stat-amount {
  font-size: 24px;
  font-weight: bold;
}

.stat-amount.credit {
  color: #27ae60;
}

.stat-amount.debit {
  color: #e74c3c;
}

.recent-transactions {
  background: #fff;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.recent-transactions h2 {
  margin-bottom: 20px;
  color: #2c3e50;
}

.transaction-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #eee;
}

.transaction-item:last-child {
  border-bottom: none;
}

.transaction-info {
  display: flex;
  flex-direction: column;
}

.transaction-description {
  font-size: 16px;
  color: #2c3e50;
}

.transaction-date {
  font-size: 14px;
  color: #666;
  margin-top: 4px;
}

.transaction-amount {
  font-weight: bold;
  font-size: 16px;
}

.transaction-amount.credit {
  color: #27ae60;
}

.transaction-amount.debit {
  color: #e74c3c;
}

.loading {
  text-align: center;
  padding: 40px;
  color: #666;
}

.error {
  text-align: center;
  padding: 40px;
  color: #e74c3c;
}

.no-transactions {
  text-align: center;
  color: #666;
  padding: 20px;
} 