/* Modern reset and base styles */
.create-hackathon {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  background: #f8fafc;
  min-height: 100vh;
}

.create-hackathon h1 {
  font-size: 2.5rem;
  color: #1e293b;
  text-align: center;
  margin-bottom: 40px;
  font-weight: 700;
  position: relative;
}

.create-hackathon h1:after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 4px;
  background: #3b82f6;
  border-radius: 2px;
}

/* Form sections styling */
.form-section {
  background: white;
  padding: 35px;
  border-radius: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  margin-bottom: 35px;
  border: 1px solid #e2e8f0;
  transition: all 0.3s ease;
}

.form-section:hover {
  transform: translateY(-3px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.12);
}

.form-section h2 {
  font-size: 1.5rem;
  color: #334155;
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 2px solid #e2e8f0;
  font-weight: 600;
}

/* Form controls */
.form-group {
  margin-bottom: 28px;
  position: relative;
}

.form-row {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
}

.form-group label {
  display: block;
  font-size: 1rem;
  font-weight: 600;
  color: #334155;
  margin-bottom: 10px;
  transition: color 0.2s ease;
}

input, textarea, select {
  width: 100%;
  padding: 14px 18px;
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background: #ffffff;
  color: #1e293b;
}

input:hover, textarea:hover, select:hover {
  border-color: #cbd5e1;
  background: #f8fafc;
}

input:focus, textarea:focus, select:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 4px rgba(59, 130, 246, 0.15);
  background: #ffffff;
}

textarea {
  min-height: 120px;
  resize: vertical;
}

/* Round configuration styling */
.round-config {
  background: #ffffff;
  padding: 30px;
  border-radius: 16px;
  margin-bottom: 30px;
  border: 1px solid #e2e8f0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.round-config:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
}

.round-config h3 {
  color: #1e293b;
  font-size: 1.3rem;
  margin-bottom: 24px;
  padding-bottom: 12px;
  border-bottom: 2px solid #e2e8f0;
  display: flex;
  align-items: center;
  gap: 12px;
}

.round-config h3:before {
  content: '🎯';
  font-size: 1.4rem;
}

/* Info boxes */
.info-box {
  background-color: #f8f9fa;
  border-left: 4px solid #007bff;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.info-box h4 {
  color: #1e40af;
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.info-box p {
  color: #475569;
  line-height: 1.6;
}

/* Example boxes */
.example-box {
  background: linear-gradient(to right, #fffbeb, #fef3c7);
  border: 1px solid #fcd34d;
  padding: 24px;
  margin-top: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(252, 211, 77, 0.1);
}

.example-box h4 {
  color: #92400e;
  margin-bottom: 12px;
  font-size: 1.1rem;
}

.example-box ul {
  margin: 0;
  padding-left: 20px;
}

.example-box li {
  color: #92400e;
  margin-bottom: 8px;
  line-height: 1.5;
}

/* Buttons */
.add-btn, .remove-btn {
  padding: 10px 18px;
  border-radius: 10px;
  font-weight: 600;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.add-btn {
  background: #22c55e;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.9rem;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  margin-top: 12px;
  transition: all 0.2s ease;
  width: auto;
  min-width: 120px;
  justify-content: center;
}

.add-btn:hover {
  background: #16a34a;
  transform: translateY(-1px);
}

.add-btn:before {
  font-size: 1.1rem;
  font-weight: 600;
}

.remove-btn {
  background: #ef4444;
  color: white;
  border: none;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
  flex-shrink: 0;
  width: auto;
}

.remove-btn:hover {
  background: #dc2626;
}

/* Submit button */
.submit-button {
  background: linear-gradient(135deg, #3b82f6 0%, #2563eb 100%);
  color: white;
  padding: 18px 36px;
  border: none;
  border-radius: 12px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 400px;
  margin: 50px auto 0;
  display: block;
  text-align: center;
  box-shadow: 0 4px 6px rgba(59, 130, 246, 0.2);
  position: relative;
  overflow: hidden;
}

.submit-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 12px rgba(59, 130, 246, 0.3);
}

.submit-button:active {
  transform: translateY(-1px);
}

/* Lists and items */
.criteria-list,
.requirements-list,
.files-list,
.sections-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #f8fafc;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #e2e8f0;
  margin-top: 12px;
}

.criteria-item,
.requirement-item,
.file-item,
.section-item {
  display: flex;
  gap: 16px;
  align-items: center;
  background: white;
  padding: 12px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.criteria-item:hover,
.requirement-item:hover,
.file-item:hover,
.section-item:hover {
  transform: translateX(4px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
}

/* Updated list styling */
.criteria-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: #f8fafc;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #e2e8f0;
}

.criteria-item {
  display: flex;
  gap: 12px;
  align-items: center;
  background: white;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.criteria-item input {
  flex: 1;
  margin: 0;
}

/* Field hints */
.field-hint {
  font-size: 0.9rem;
  color: #64748b;
  margin-bottom: 8px;
  font-style: italic;
}

/* Checkbox group styling */
.checkbox-group {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 8px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px 16px;
  background: #f8fafc;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.checkbox-label:hover {
  background: #f1f5f9;
}

/* Responsive design */
@media (max-width: 768px) {
  .create-hackathon {
    padding: 20px 15px;
  }

  .form-section {
    padding: 20px;
  }

  .form-row {
    flex-direction: column;
  }

  .submit-button {
    width: 100%;
    max-width: none;
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.form-section {
  animation: fadeIn 0.5s ease forwards;
}

.banner-upload {
  margin-bottom: 2rem;
}

.banner-preview {
  position: relative;
  margin: 1rem 0;
  border-radius: 8px;
  overflow: hidden;
  max-width: 600px;
}

.banner-preview img {
  width: 100%;
  height: auto;
  display: block;
}

.remove-banner {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.2s;
}

.remove-banner:hover {
  background: rgba(0, 0, 0, 0.8);
}

.upload-container {
  margin: 1rem 0;
}

.file-input {
  display: none;
}

.upload-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: 2px dashed #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.upload-button:hover {
  background-color: #e0e0e0;
  border-color: #999;
}

.upload-requirements {
  color: #666;
  margin-top: 0.5rem;
}

.round-number {
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 4px 8px;
  display: inline-block;
  margin-top: 8px;
  font-weight: 500;
  color: #666;
}