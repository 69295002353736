.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  height: 70px;
  display: flex;
  align-items: center;
}

.header-container {
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  gap: 24px;
}

.logo {
  display: flex;
  align-items: center;
  gap: 12px;
}

.logo-icon {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.logo-text {
  font-size: 1.5rem;
  font-weight: 700;
  color: #000000;
}

.search-container {
  flex: 1;
  max-width: 600px;
  margin: 0 20px;
}

.search-container form {
  display: flex;
  align-items: center;
  position: relative;
}

.search-input {
  width: 100%;
  padding: 14px 45px 14px 20px;
  border: 2px solid #000000;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.search-input:focus {
  border-color: #FFD700;
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 215, 0, 0.2);
}

.search-input::placeholder {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}

.search-button {
  position: absolute;
  right: 12px;
  background: none;
  border: none;
  color: #000000;
  cursor: pointer;
  padding: 6px;
  font-size: 20px;
}

.nav-menu {
  display: flex;
  align-items: center;
  gap: 40px;
}

.nav-links {
  display: flex;
  gap: 30px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-link {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border-radius: 6px;
  transition: all 0.3s ease;
  color: #000000;
  text-decoration: none;
  font-weight: 600;
  border: 2px solid transparent;
}

.nav-icon {
  font-size: 20px;
  font-weight: bold;
}

.nav-link:hover {
  background-color: rgba(255, 215, 0, 0.15);
  transform: translateY(-2px);
  color: #000000;
  border-color: #FFD700;
  box-shadow: 0 4px 12px rgba(255, 215, 0, 0.2);
}

.notification-link {
  position: relative;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #FFD700;
  color: #000000;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 10px;
  min-width: 20px;
  text-align: center;
}

.auth-buttons {
  display: flex;
  gap: 20px;
  align-items: center;
}

.auth-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  background: #000000;
  border: 2px solid #000000;
  border-radius: 25px;
  color: #FFD700;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  text-decoration: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
}

.auth-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FFD700;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.auth-button:hover {
  background-color: #FFD700;
  border-color: #FFD700;
  color: #000000;
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(255, 215, 0, 0.4);
}

.auth-button:hover::before {
  opacity: 1;
}

.auth-button span {
  position: relative;
  z-index: 1;
}

.auth-button .auth-icon {
  font-size: 18px;
  position: relative;
  z-index: 1;
}

/* Remove old auth button styles */
.sign-in, .sign-up {
  display: none;
}

.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 24px;
  color: #000000;
}

.user-role {
  color: #4a5568;
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 15px;
}

.sign-out {
  background-color: #e53e3e;
  color: white;
  padding: 10px 20px;
  border-radius: 6px;
  border: none;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sign-out:hover {
  background-color: #c53030;
}

/* Animation for mobile menu */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.nav-menu.active {
  animation: slideDown 0.3s ease forwards;
}

/* Profile dropdown styles */
.profile-dropdown {
  position: relative;
}

.profile-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: none;
  border: 2px solid #000000;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 600;
}

.profile-button:hover {
  background-color: rgba(255, 215, 0, 0.15);
  border-color: #FFD700;
  box-shadow: 0 4px 12px rgba(255, 215, 0, 0.2);
}

.profile-icon {
  font-size: 18px;
  color: #000000;
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  width: 240px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 8px 0;
  z-index: 1000;
  animation: fadeIn 0.2s ease;
}

.dropdown-header {
  padding: 12px 16px;
}

.user-name {
  display: block;
  font-weight: 700;
  color: #000000;
  font-size: 14px;
}

.user-role {
  display: block;
  color: #000000;
  font-size: 12px;
  font-weight: 600;
  margin-top: 4px;
  opacity: 0.8;
}

.dropdown-divider {
  height: 1px;
  background-color: #e2e8f0;
  margin: 8px 0;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 16px;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid transparent;
}

.dropdown-item:hover {
  background-color: rgba(255, 215, 0, 0.15);
  color: #000000;
  border-color: #FFD700;
}

.dropdown-icon {
  font-size: 16px;
}

.text-red {
  color: #000000;
}

.text-red:hover {
  background-color: rgba(255, 215, 0, 0.1);
  color: #000000;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Update mobile styles */
@media (max-width: 768px) {
  .search-container {
    display: none;
  }

  .mobile-menu-button {
    display: block;
  }

  .nav-menu {
    display: none;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    background-color: white;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-menu.active {
    display: flex;
  }

  .nav-links {
    flex-direction: column;
    width: 100%;
  }

  .auth-button {
    width: 100%;
    justify-content: center;
    border-radius: 12px;
  }

  .user-role {
    width: 100%;
    text-align: center;
    padding: 12px 20px;
    background-color: rgba(255, 215, 0, 0.1);
    border-radius: 6px;
    margin-bottom: 10px;
  }

  .sign-out {
    width: 100%;
  }

  .nav-link {
    padding: 14px;
    border-radius: 8px;
    background-color: rgba(255, 215, 0, 0.1);
    margin-bottom: 8px;
    font-weight: 600;
    border: 2px solid transparent;
  }

  .nav-link:hover {
    background-color: rgba(255, 215, 0, 0.15);
    border-color: #FFD700;
    box-shadow: 0 4px 12px rgba(255, 215, 0, 0.2);
  }

  .notification-badge {
    position: static;
    margin-left: 8px;
  }

  .profile-dropdown {
    width: 100%;
  }

  .dropdown-menu {
    position: static;
    width: 100%;
    box-shadow: none;
    border: 1px solid #e2e8f0;
    margin-top: 8px;
  }

  .profile-button {
    width: 100%;
    justify-content: center;
  }

  .nav-link {
    color: #000000;
    background-color: transparent;
  }

  .dropdown-item:hover {
    background-color: rgba(255, 215, 0, 0.15);
    border-color: #FFD700;
  }
} 