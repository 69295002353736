/* Dashboard specific layout styles */
.layout-container {
    display: flex;
    min-height: 100vh;
    width: 100%;
    background: #f8f9fa;
}

.layout-content {
    flex: 1;
    margin-left: 280px; /* Same as sidebar width */
    padding: 24px;
    min-height: 100vh;
    transition: margin-left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    overflow-y: auto;
}

/* Adjust content when sidebar is collapsed */
.sidebar.collapsed + .layout-content {
    margin-left: 64px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .layout-content {
        margin-left: 0;
        padding: 16px;
    }
    
    .sidebar.collapsed + .layout-content {
        margin-left: 0;
    }
}
