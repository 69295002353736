.footer {
  background-color: #000000;
  border-top: 1px solid rgba(255, 215, 0, 0.1);
  padding: 32px 0 16px;
  color: #ffffff;
}

.footer-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 24px;
}

.footer-content {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gap: 32px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(255, 215, 0, 0.1);
}

.footer-brand {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.footer-logo {
  display: flex;
  align-items: center;
  gap: 12px;
  text-decoration: none;
  transition: transform 0.3s ease;
}

.footer-logo:hover {
  transform: translateY(-2px);
}

.footer-logo-img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.footer-logo-text {
  font-size: 1.5rem;
  font-weight: 600;
  color: #FFD700;
}

.footer-description {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  line-height: 1.5;
}

.footer-section h4 {
  color: #FFD700;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin-bottom: 8px;
}

.footer-links a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  font-size: 0.875rem;
  transition: all 0.3s ease;
  display: inline-block;
  padding: 4px 0;
}

.footer-links a:hover {
  color: #FFD700;
  transform: translateX(4px);
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  font-size: 0.875rem;
}

.footer-copyright {
  color: rgba(255, 255, 255, 0.6);
}

.footer-social {
  display: flex;
  gap: 16px;
}

.footer-social a {
  color: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  font-size: 1.2rem;
  padding: 8px;
  border-radius: 50%;
  background: rgba(255, 215, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-social a:hover {
  color: #FFD700;
  background: rgba(255, 215, 0, 0.2);
  transform: translateY(-2px);
}

.footer-legal {
  display: flex;
  gap: 24px;
}

.footer-legal a {
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  transition: all 0.3s ease;
}

.footer-legal a:hover {
  color: #FFD700;
}

/* Mobile styles */
@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    gap: 24px;
  }

  .footer-brand {
    text-align: center;
    align-items: center;
  }

  .footer-section {
    text-align: center;
  }

  .footer-bottom {
    flex-direction: column;
    gap: 16px;
    text-align: center;
  }

  .footer-social {
    justify-content: center;
  }

  .footer-legal {
    justify-content: center;
    flex-wrap: wrap;
  }
} 